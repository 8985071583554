import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/frontend.scss"
import TechHeader from "../../images/header-za-tehnologiju.png";

import 'toasted-notes/src/styles.css';
import CareerForm from '../../components/Career/CareerForm'

import Headline from '../../components/Career/Headline'
import List from '../../components/Career/List'
import ReasonToJoinUs from '../../components/Career/ReasonToJoinUs'
export default class CSharpDeveloper extends React.Component {

    render() {
        return (
            <Layout>
                <SEO title="C# developer" keywords={[`S7 Design`, `coding`, `develop`,'Python', 'Django framework', 'web development']} />
                <div className="frontend-main" style={{ backgroundImage: `url(${TechHeader})` }}>
                    <div className="container">
                        <Headline title={'C# developer'} />
                        <div className="row frontend-main--skills">
                         
                            <div className="col-md-6 col-12">
                            <List 
                               title={"You are excellent candidate if you:"}
                               items={[
                                    'have minimum 1 year of experience',
                                    'basic knowledge MVC patern',
                                    'are fammiliar with MSSQL',
                                    'knowledge of the basic principles of application scaling',
                                    'basic knowledge for GIT and CI system',
                                    'have good verbal and written communication skills on English',
                                ]}
                            />	
                            <List 
                               title={"Position Type:"}
                               items={[
                                    'full time employment'
                                ]}
                            />	
                            </div>
                            <div className="checked col-md-6 col-12">
                            <List 
                               title={"What we offer:"}
                               items={[
                                    'opportunity to work in a young, constructive and agile team',
                                    'competitive salary',
                                    'learning and development opportunities',
                                ]}
                            />	
                            </div>
                            <div className="w-100 height"></div>
                            <ReasonToJoinUs />
                            <div className=" offset-md-1 col-md-10 col-12">
                                <CareerForm contactTitle="C# developer"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )

    }
}
